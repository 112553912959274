@import "../../jslib/angular/src/scss/webfonts.css";
@import "./variables";
@import "../../jslib/angular/src/scss/bwicons/styles/style.scss";
@import "../../jslib/angular/src/scss/icons.scss";

//@import "~bootstrap/scss/bootstrap";
@import "~bootstrap/scss/_functions";
@import "~bootstrap/scss/_variables";
@import "~bootstrap/scss/_mixins";
@import "~bootstrap/scss/_root";
@import "~bootstrap/scss/_reboot";
@import "~bootstrap/scss/_type";
@import "~bootstrap/scss/_images";
@import "~bootstrap/scss/_code";
@import "~bootstrap/scss/_grid";
@import "~bootstrap/scss/_tables";
@import "~bootstrap/scss/_forms";
@import "~bootstrap/scss/_buttons";
@import "~bootstrap/scss/_transitions";
@import "~bootstrap/scss/_dropdown";
@import "~bootstrap/scss/_button-group";
@import "~bootstrap/scss/_input-group";
@import "~bootstrap/scss/_custom-forms";
@import "~bootstrap/scss/_nav";
@import "~bootstrap/scss/_navbar";
@import "~bootstrap/scss/_card";
@import "~bootstrap/scss/_breadcrumb";
@import "~bootstrap/scss/_pagination";
@import "~bootstrap/scss/_badge";
@import "~bootstrap/scss/_jumbotron";
@import "~bootstrap/scss/_alert";
@import "~bootstrap/scss/_progress";
@import "~bootstrap/scss/_media";
@import "~bootstrap/scss/_list-group";
@import "~bootstrap/scss/_close";
//@import "~bootstrap/scss/_toasts";
@import "~bootstrap/scss/_modal";
@import "~bootstrap/scss/_tooltip";
@import "~bootstrap/scss/_popover";
@import "~bootstrap/scss/_carousel";
@import "~bootstrap/scss/_spinners";
@import "~bootstrap/scss/_utilities";
@import "~bootstrap/scss/_print";

@import "~ngx-toastr/toastr";
@import "~#sweetalert2";

@import "./base";
@import "./buttons";
@import "./callouts";
@import "./cards";
@import "./forms";
@import "./navigation";
@import "./modals";
@import "./pages";
@import "./plugins";
@import "./tables";
@import "./toasts";


/**** START Vaultwarden CHANGES ****/
/* This combines all selectors extending it into one */
%vw-hide { display: none !important; }

/* This allows searching for the combined style in the browsers dev-tools (look into the head tag) */
#vw-hide, head { @extend %vw-hide; }

/* Hide any link pointing to billing */
a[href$="/settings/billing"] { @extend %vw-hide; }

/* Hide any link pointing to subscriptions */
a[href$="/settings/subscription"] { @extend %vw-hide; }

/* Hide any link pointing to Sponsored Families */
a[href$="/settings/sponsored-families"] { @extend %vw-hide; }

/* Hide the `Enterprise Single Sign-On` button on the login page */
a[href$="/sso"] { @extend %vw-hide; }

/* Hide the info box that advertises Bitwarden Send */
app-send-info.d-block { @extend %vw-hide; }

/* Hide Two-Factor menu in Organization settings */
app-org-settings a[href$="/settings/two-factor"] { @extend %vw-hide; }

/* Hide organization plans */
app-organization-plans > form > div.form-check { @extend %vw-hide; }
app-organization-plans > form > h2.mt-5 { @extend %vw-hide; }

/* Hide the `This account is owned by a business` checkbox and label */
#ownedBusiness, label[for^=ownedBusiness] { @extend %vw-hide; }

/* Hide External Id field for Collections */
app-collection-add-edit form div.form-group:nth-child(2) { @extend %vw-hide; }

/* Hide the radio button and label for the `Custom` org user type */
#userTypeCustom, label[for^=userTypeCustom] { @extend %vw-hide; }

/* Hide the warning that policy config is moving to Business Portal */
app-org-policies > app-callout { @extend %vw-hide; }

/* Hide Business Name and Identifier */
app-org-account form div.form-group:nth-child(3) { display: none; }
app-org-account form div.form-group:nth-child(4) { display: none; }

/* Hide Tax Info and Form in Organization settings */
app-org-account > div.secondary-header:nth-child(3) { @extend %vw-hide; }
app-org-account > div.secondary-header:nth-child(3) + p { @extend %vw-hide; }
app-org-account > div.secondary-header:nth-child(3) + p + form { @extend %vw-hide; }
/**** END Vaultwarden CHANGES ****/
